import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BASE_URL from './api';

interface MerchantData {
  businessName?: string;
  businessDescription?: string;
  businessAddress?: string;
  country?:string;
  state?:string;
  bvn?:string
  meansOfIdentification?:string
  identificationNumber?:string
  identificationUrl?:string 
  phoneNumber?: string;
  email?: string;
  password?: string;
  verificationStatus?: string;
  roles?: string;
}

export interface MerchantLogin {
  businessName: string;
  phoneNumber: string;
  email: string;
  businessAddress: string;
  password: string;
  token: string;
  verificationStatus: string;
  roles: string;
}

interface MerchantAuthState {
  isLoggedIn: boolean;
  user: MerchantLogin | null;
  error: string | null;
  success: string | null;
}

const initialState: MerchantAuthState = {
  isLoggedIn: false,
  user: null,
  error: null,
  success: null,
};

const authSlice = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    login(state, action: PayloadAction<MerchantLogin>) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    resetPasswordInitiated(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    resetPasswordSuccess(state) {
      state.error = null; // Reset error on success
    },
    resetPasswordFailure(state, action: PayloadAction<string>) {
      state.error = action.payload; // Set error message
    },
    // Token verification reducers
    verifyTokenInitiated(state) {
      state.error = null;
    },
    verifyTokenSuccess(state) {
      state.error = null;
    },
    verifyTokenFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const {
  login,
  logout,
  resetPasswordInitiated,
  resetPasswordSuccess,
  resetPasswordFailure,
  verifyTokenInitiated,
  verifyTokenSuccess,
  verifyTokenFailure,
} = authSlice.actions;

// Initiate Password Reset
export const initiateResetPassword = (email: string) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/init-merchant-reset/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await response.json();

    // Check for specific responseCode "040" indicating that the email was not found
    if (data.responseCode === "040") {
      throw new Error('MERCHANT NOT FOUND FOR EMAIL');
    }

    if (!response.ok) {
      throw new Error('Failed to initiate password reset. Please check your email.');
    }

    dispatch(resetPasswordInitiated()); // Dispatch necessary actions
    return data; // Return data for further use if needed
  } catch (error: any) {
    console.error(error);
    dispatch(resetPasswordFailure(error.message)); // Dispatch failure action with the error message
    throw error; // Propagate error to the calling function
  }
};



// Reset Password
export const resetPassword = (token: string, newPassword: string) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/reset-merchant-pass?token=${token}&newPassword=${newPassword}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      // Check if the error message is "TOKEN NOT FOUND"
      if (errorData.responseCode === "050") {
        throw new Error('Token not found. Please check the link or request a new one.');
      } else if (errorData.responseCode === "020") {
        throw new Error('Token expired. Please request a new password reset link.');
      } else {
        throw new Error(errorData.message = 'Failed to reset password.');
      }
     // const errorData = await response.json();
    //  throw new Error(errorData.message || 'Failed to reset password.');
    }

    const data = await response.json();
    dispatch(resetPasswordSuccess()); 
    dispatch(login(data?.data)); 
  } catch (error: any) {
    dispatch(resetPasswordFailure(error.message)); 
  }
};

// Merchant Tier1
export const MerchantTier1 = (userData: MerchantData) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/merchant/tier/one/verify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      }
    );

    const data = await response.json();
    if (!response.ok || data.responseCode === '002') {
      throw new Error('Verification failed!');
    }
    await localStorage.setItem('user', JSON.stringify(data)); // Assuming the API returns user data
    dispatch(login(data));
  } catch (error) {
    console.error(error);
  }
};

// Merchant Login
export const MerchantLoginUser = (userData: { email: string; password: string }) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/merchantLogin`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      }
    );

    const data = await response.json();

    if (!response.ok || data.responseCode === 'E02' || data.responseCode === 'E01') {
      // If response is not OK or the responseCode is E02 (User Not Found)
      throw new Error(data.responseMessage= ' Please check your credentials.');
    }

    await localStorage.setItem('user', JSON.stringify(data)); // Store user data in localStorage
    dispatch(login(data?.data)); // Dispatch login action with user data
  } catch (error) {
    console.error(error);
    throw error; // Handle error appropriately
  }
};


// Merchant Sign Up
export const MerchantSignUp = (userData: MerchantData) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/merchantSignUp`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      }
    );

    // if (!response.ok) {
    //   throw new Error('Signup failed!');
    // }

    const data = await response.json();
    if (!response.ok || data.responseCode === '002') {
      throw new Error('Signup failed!');
    }
    await localStorage.setItem('user', JSON.stringify(data)); // Assuming the API returns user data
    dispatch(login(data));
  } catch (error) {
    console.error(error);
  }
};

// Token verification
export const verifyToken = (token: string) => async (dispatch: any) => {
  dispatch(verifyTokenInitiated());
  try {
    const response = await fetch(
      `${BASE_URL}/merchant-verify-email?token=${token}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to verify token.');
    }

    const data = await response.json();
    console.log('Token verified successfully:', data);
    dispatch(verifyTokenSuccess()); // Dispatch success action
  } catch (error: any) {
    dispatch(verifyTokenFailure(error.message)); // Dispatch failure action
    console.error(error);
  }
};

// Logout Merchant
export const logoutMerchant = () => async (dispatch: any) => {
  await localStorage.removeItem('token');
  dispatch(logout());
};

export default authSlice.reducer;
export type { MerchantAuthState };
